import Axios from 'axios'
import moment from 'moment-business-days'
const FileSaver = require('file-saver')

const PRICES_URL_END_POINT = '/productos'
const MINUTES_TO_WAIT_IN_MILLISECONDS = 2 * 60 * 1000

export const downloadPricesList = async ({ filter, format }) => {
  const config = {
    responseType: 'arraybuffer',
    timeout: MINUTES_TO_WAIT_IN_MILLISECONDS,
  }

  const body = {
    filter,
    format,
  }

  const response = await Axios.post(`${PRICES_URL_END_POINT}/downloadPricesList`, body, config)

  if (response && response.data) {
    let fileExtension = format === 'pdf' ? '.pdf' : '.xlsx'
    FileSaver.saveAs(
      new Blob([response.data]),
      `Precios-${moment().format('YYYYMMDD')}${fileExtension}`,
      { autoBOM: true },
    )
  }
}

export const getLineasCatalogos = async (data) => {
  const response = await Axios.get('/lineasCatalogos/getAllLineaCatalogo')
  return response.data
}

export const autocompleteSearch = async (query) => {
  const response = await Axios.get(`/productos/searchProductsAutocomplete?query=${query}`)
  return response.data
}
