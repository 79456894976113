import {
  AUTOCOMPLETE_SEARCH_SUCCESS,
  AUTOCOMPLETE_CART_PRODUCTS_SUCCESS,
  CLEAR_AUTOCOMPLETE_OPTIONS,
  SET_SHOW_CATEGORIES,
  SET_SELECTED_CATALOG_LINE,
  SET_SHOW_QUICK_CART,
  SET_SHOW_ESTIMATE,
  SET_SHOW_PRICES_LIST_MODAL,
  DOWNLOAD_PRICES_LIST,
  DOWNLOAD_PRICES_LIST_SUCCESS,
  DOWNLOAD_PRICES_LIST_ERROR,
  GET_BRANDS_BY_SEGMENTS,
  GET_BRANDS_BY_SEGMENTS_SUCCESS,
  GET_BRANDS_BY_SEGMENTS_ERROR,
  GET_PRODUCTS_BY_LINEA_CATALOGO,
  GET_PRODUCTS_BY_LINEA_CATALOGO_SUCCESS,
  GET_PRODUCTS_BY_LINEA_CATALOGO_ERROR,
  SEARCH_CATALOG_PRODUCTS,
  SEARCH_CATALOG_PRODUCTS_SUCCESS,
  SEARCH_CATALOG_PRODUCTS_ERROR,
  SHOW_ITEM_DETAIL,
  HIDE_ITEM_DETAIL,
  APPLY_DISCOUNTS_TO_PRODUCTS_SUCCESS,
  APPLY_DISCOUNTS_TO_PRODUCTS_ERROR,
  APPLY_DISCOUNTS_TO_PRODUCTS_LOADING,
  GO_ONLINE,
  GO_OFFLINE,
  SET_CATALOG_SEGMENT,
  CHANGE_CURRENT_QUOTATION,
  ADD_PRODUCTS_TO_CURRENT_SUCCESS,
  SET_FILTER_SELECTED,
  SHOW_OFFLINE_MERGE,
  SET_MERGE_ORDERS,
  GET_LINEAS_CATALOGOS,
  GET_LINEAS_CATALOGOS_SUCCESS,
  GET_PRODUCTS_BY_VENDOR_ID,
  GET_PRODUCTS_BY_VENDOR_ID_SUCCESS,
  GET_PRODUCTS_BY_VENDOR_ID_ERROR,
  GET_ONLINE_ORDER_TO_MERGE_SUCCESS,
  GET_ONLINE_QUOTATION_TO_MERGE_SUCCESS,
  CLEAR_SEARCH_PRODUCT,
  GET_NEWS_FOR_FAVORITES,
  GET_NEWS_FOR_FAVORITES_SUCCESS,
  TOGGLE_FILTER_BY_STOCK,
  TOGGLE_SHOW_BADGE,
} from '../constants/ActionsTypes'

import { isElectron } from '../electron/tools'

const initialState = {
  showCategories: false,
  showQuickCart: false,
  showEstimate: false,
  showPricesListModal: false,
  downloadingPricesList: false,
  isSearching: false,
  selectedSegments: [],
  products: [],
  selectedCatalogLine: null,
  searchRequest: [],
  filterByStock: false,
  autocompleteOptions: [],
  autocompleteProductsOptions: [],
  itemDetail: {
    show: false,
    showDealLabel: false,
  },
  catalogSegment: null,
  filterSelected: 'PC_ASC',
  showOfflineMerge: false,
  ordersToMerge: null,
  onlineOrdersToMerge: {},
  onlineQuotationsToMerge: {},
  lineasCatalogos: null,
  favoritesBadgeFlag: true,
  isLoadingApplyDiscounts: false,
  productsWithDifferences: {
    existDifferences: false,
    withNewStock: [],
    withNewPrice: [],
    withNewOfferPrice: [],
  },
}

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SHOW_CATEGORIES:
      return { ...state, showCategories: !state.showCategories }
    case SET_SELECTED_CATALOG_LINE:
      return { ...state, selectedCatalogLine: action.payload }
    case SET_SHOW_QUICK_CART:
      return {
        ...state,
        showQuickCart: !state.showQuickCart,
        showEstimate: false,
      }
    case SET_SHOW_ESTIMATE:
      return {
        ...state,
        showEstimate: !state.showEstimate,
        showQuickCart: false,
      }
    case AUTOCOMPLETE_SEARCH_SUCCESS:
      return {
        ...state,
        autocompleteOptions: action.payload,
      }
    case AUTOCOMPLETE_CART_PRODUCTS_SUCCESS:
      return {
        ...state,
        autocompleteProductsOptions: action.payload,
      }
    case CLEAR_AUTOCOMPLETE_OPTIONS:
      return {
        ...state,
        autocompleteOptions: [],
        autocompleteProductsOptions: []
      }
    case SET_SHOW_PRICES_LIST_MODAL:
      return { ...state, showPricesListModal: !state.showPricesListModal }
    case DOWNLOAD_PRICES_LIST:
      return { ...state, downloadingPricesList: true }
    case DOWNLOAD_PRICES_LIST_SUCCESS:
    case DOWNLOAD_PRICES_LIST_ERROR:
      return { ...state, downloadingPricesList: false, showPricesListModal: false }
    case GET_PRODUCTS_BY_VENDOR_ID:
      return { ...state, products: [], isSearching: false }
    case GET_PRODUCTS_BY_VENDOR_ID_SUCCESS:
      return { ...state, products: action.data }
    case GET_PRODUCTS_BY_VENDOR_ID_ERROR:
      return { ...state, products: action }
    case GET_PRODUCTS_BY_LINEA_CATALOGO:
      return { ...state, products: [], isSearching: false }
    case GET_PRODUCTS_BY_LINEA_CATALOGO_SUCCESS:
      return { ...state, products: action.data }
    case GET_PRODUCTS_BY_LINEA_CATALOGO_ERROR:
      return { ...state, products: action }
    case SEARCH_CATALOG_PRODUCTS:
      return { ...state, products: [], searchRequest: action.payload, isSearching: true }
    case SEARCH_CATALOG_PRODUCTS_SUCCESS:
      return { ...state, products: action.data }
    case SEARCH_CATALOG_PRODUCTS_ERROR:
      return { ...state, products: action }
    case GET_BRANDS_BY_SEGMENTS:
      return { ...state, selectedSegments: null }
    case GET_BRANDS_BY_SEGMENTS_SUCCESS:
      return { ...state, selectedSegments: action.data }
    case GET_BRANDS_BY_SEGMENTS_ERROR:
      return { ...state, selectedSegments: action }
    case SHOW_ITEM_DETAIL:
      return { ...state, itemDetail: action.payload }
    case HIDE_ITEM_DETAIL:
      return {
        ...state,
        itemDetail: {
          show: false,
          showDealLabel: false,
        },
      }
    case APPLY_DISCOUNTS_TO_PRODUCTS_SUCCESS:
      return {
        ...state,
        itemDetail: {
          ...state.itemDetail,
          discount: action.payload,
        },
        isLoadingApplyDiscounts: false,
      }
    case APPLY_DISCOUNTS_TO_PRODUCTS_LOADING:
      return { ...state, isLoadingApplyDiscounts: true }
    case APPLY_DISCOUNTS_TO_PRODUCTS_ERROR:
      return {
        ...state,
        itemDetail: action,
        isLoadingApplyDiscounts: false,
      }
    case GO_ONLINE:
      if (isElectron()) {
        return { ...state, offline: false }
      }
      return { ...state }
    case GO_OFFLINE:
      if (isElectron()) {
        return { ...state, offline: true }
      }
      return { ...state }
    case SET_CATALOG_SEGMENT:
      return { ...state, catalogSegment: action.payload, showCategories: true }
    case SET_FILTER_SELECTED:
      return { ...state, filterSelected: action.payload }
    case CHANGE_CURRENT_QUOTATION:
      return { ...state, showEstimate: true }
    case ADD_PRODUCTS_TO_CURRENT_SUCCESS:
      return { ...state, showQuickCart: false }
    case SHOW_OFFLINE_MERGE:
      if (isElectron()) {
        return { ...state, showOfflineMerge: action.payload }
      }
      return { ...state }
    case SET_MERGE_ORDERS:
      if (isElectron()) {
        return { ...state, ordersToMerge: action.payload }
      }
      return { ...state }
    case GET_LINEAS_CATALOGOS:
      return { ...state, lineasCatalogos: null }
    case GET_LINEAS_CATALOGOS_SUCCESS:
      return { ...state, lineasCatalogos: action.payload.response }
    case GET_ONLINE_ORDER_TO_MERGE_SUCCESS:
      return {
        ...state,
        onlineOrdersToMerge: {
          ...state.onlineOrdersToMerge,
          [action.payload.id]: action.payload,
        },
      }
    case GET_ONLINE_QUOTATION_TO_MERGE_SUCCESS:
      return {
        ...state,
        onlineQuotationsToMerge: {
          ...state.onlineQuotationsToMerge,
          [action.payload.id]: action.payload,
        },
      }
    case CLEAR_SEARCH_PRODUCT:
      return { ...state, products: [] }
    case TOGGLE_FILTER_BY_STOCK:
      return {
        ...state,
        filterByStock: !state.filterByStock,
      }
    case GET_NEWS_FOR_FAVORITES:
      return {
        ...state,
        productsWithDifferences: {
          existDifferences: false,
          withNewStock: [],
          withNewPrice: [],
          withNewOfferPrice: [],
        },
      }
    case GET_NEWS_FOR_FAVORITES_SUCCESS:
      return { ...state, productsWithDifferences: action.payload }

    case TOGGLE_SHOW_BADGE:
      return { ...state, favoritesBadgeFlag: false }
    default:
      return state
  }
}

export default rootReducer
